.image-uploader {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #363636;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.file-input {
  display: none;
}

.file-cta {
  display: flex;
  align-items: center;
}

.file-icon {
  margin-right: 0.5rem;
}

.file-label-text {
  font-size: 16px;
}

.selected-file {
  margin-top: 1rem;
  font-size: 16px;
}

.selected-file-preview {
  margin-top: 1rem;
  max-width: 200px;
}







